import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import EBikes from '@components/EBikes/EBikes';

const EBikePage = ({ location }) => {
  return (
    <Layout location={location} map>
      <Seo
        title="E-Bike da Bambino e Adulto a Champoluc - Tiziano Bieller Sport"
        lang="it"
      />
      <EBikes detail />
    </Layout>
  );
};

export default EBikePage;
